import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Text, Container } from "theme-ui"

const NotFoundPage = () => (
  <Layout scrollOnNavLink={false}>
    <SEO title="404: Not found" />
    <Container
      sx={{
        height: "93vh",
        pt: "6",
      }}>
      <Heading as="h2" variant="text.section_headline">
        Not found
      </Heading>
      <Text variant="text.body">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
